// my firm
// export const Domain = 'myfarm-dev.au.auth0.com';
// export const clientId = 'W5B3uWyDXYJNgLxczSmofyasCn8LZffc';
// export const audience = 'https://myfarm-dev.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'myfarm-dev';
// export const singup_auth = 'https://myfarm-dev.au.auth0.com/dbconnections/signup';
// export const duvalapi = 'https://24vkgalv5c.execute-api.ap-southeast-2.amazonaws.com/dev';
// export const multisavedata = 'https://24vkgalv5c.execute-api.ap-southeast-2.amazonaws.com/dev';
// export const Biometricverify = 'https://duval-identity-verification.azurewebsites.net';
// export const twilio = 'https://myfarm1-6510.twil.io';


// my firm retails Dev
// export const LoginUrl="https://myfarmuser-dev.invsta.io/userlogin"
// export const Domain = 'dev-r914xhfs.au.auth0.com';
// export const clientId = '9OHhDS1EgmF8Q6BSNJyt6ESXtSw18Qqj';
// export const audience = 'https://dev-r914xhfs.au.auth0.com/api/v2/';
// export const CHECK_ENV = 'dev';
// export const connection = 'myfarm-retail';
// export const singup_auth = 'https://dev-r914xhfs.au.auth0.com/dbconnections/signup';
// export const duvalapi = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com/dev';
// export const multisavedata = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com/dev';
// export const Biometricverify = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com/dev';
// export const twilio = 'https://awlf2m9k8a.execute-api.ap-southeast-2.amazonaws.com/dev';

//my firm retails Stage
export const LoginUrl="https://myfarm-user.invsta.io/userlogin"
export const Domain = 'myfarm-nz.au.auth0.com';
export const clientId = 'ZoEsCV8wDpycs96AmjRkPRakm4pJzcpV';
export const audience = 'https://myfarm-nz.au.auth0.com/api/v2/';
export const CHECK_ENV = 'stag';
export const connection = 'my-farm-nz';
export const singup_auth = 'https://myfarm-nz.au.auth0.com/dbconnections/signup';
export const duvalapi = 'https://dk8daj5mgp5cf.cloudfront.net/';
export const multisavedata = 'https://dk8daj5mgp5cf.cloudfront.net/';
export const Biometricverify = 'https://dk8daj5mgp5cf.cloudfront.net/';
export const twilio = 'https://dk8daj5mgp5cf.cloudfront.net/';
